import { Injectable, EventEmitter } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { EventEmitterService } from './event-emitter.service';

@Injectable()
export class SocketService {
    private socket;

    constructor() { }

    connect() {
        this.socket = io(environment.urlSocket);
        this.socket.on('notification', (data) => {
            const userId = JSON.parse(localStorage.getItem('userInfo')).id;
            if (data.user === userId)
                EventEmitterService.get('notification').emit(data);
        });
    }
}
