import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaskDirective } from '../directives/mask.directive';
import { UserTypePipe } from './user-type/user-type.pipe';
import { PaginatorComponent } from './paginator/paginator.component';
import { BottomTrackComponent } from './bottom-track/bottom-track.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { MoneyPipe } from './money-pipe/money.pipe';
import { CalcTimePipe } from './calc-time/calc-time.pipe';
import { ParseNumbersPipe, ValidateNumbersPipe } from './parse-number-pipe/parse-numbers.pipe';
import { ParsePeriodPipe, ParseSlaPipe } from './parse-period-pipe/parse-period.pipe';
import { FilterQuantityPipe } from './filter-quantity/filter-quantity.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    RoundProgressModule,
    NgSelectModule,
    MatCheckboxModule,
    AngularMultiSelectModule,
  ],
  declarations: [
    AppMaskDirective,
    UserTypePipe,
    PaginatorComponent,
    BottomTrackComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    MoneyPipe,
    CalcTimePipe,
    ParseNumbersPipe,
    ParsePeriodPipe,
    ParseSlaPipe,
    ValidateNumbersPipe,
    FilterQuantityPipe,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AppMaskDirective,
    UserTypePipe,
    PaginatorComponent,
    BottomTrackComponent,
    InfiniteScrollModule,
    RoundProgressModule,
    NgSelectModule,
    MatCheckboxModule,
    AngularMultiSelectModule,
    SuccessModalComponent,
    ErrorModalComponent,
    MoneyPipe,
    ParseNumbersPipe,
    ParsePeriodPipe,
    ParseSlaPipe,
    ValidateNumbersPipe,
    FilterQuantityPipe,
  ],
  providers: [
  ]
})
export class SharedModule { }
