import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserService } from './services/user.service';
import { SocketService } from './services/socket.service';
import { EventEmitterService } from './services/event-emitter.service';
import { NotificationComponent } from './layout/notification/notification.component';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  template: '<app-title></app-title><router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(private notificationService: NotificationService, private router: Router, private userService: UserService, private route: ActivatedRoute, private socketService: SocketService) {
    this.socketService.connect();
    // If user is autheticated
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!navigator.onLine) {
          EventEmitterService.get('error-modal').emit('Sem conexão com a internet');
        }
        if (localStorage.getItem('token') && this.userService.isLoggedIn() && event.urlAfterRedirects === '/login') {
          this.router.navigate(['/pedidos']);
        }
      }
    });

    EventEmitterService.get('notification').subscribe(data => {
      notificationService.notificationSource.next(data)
    });
  }
}
