import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @Input() msg: string;
  @Output() closeModal = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    if (!document.querySelector('body').classList.contains('overflow-body-disabled'))
    document.querySelector('body').classList.add('overflow-body-disabled');
  }

  close() {
    this.closeModal.emit();
  }

  ngOnDestroy() {
    if (document.querySelector('body').classList.contains('overflow-body-disabled'))
      document.querySelector('body').classList.remove('overflow-body-disabled');
  }

}
