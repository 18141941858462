import { Injectable } from '@angular/core';
import { AcceptanceRuleModel } from '../models/acceptation-rule.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestHelper } from '../helpers/requestHelper';

@Injectable({
  providedIn: 'root'
})
export class AcceptanceRuleService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async create(acceptanceRule: AcceptanceRuleModel): Promise<any> {
    return await this._http.post(`${this.url}acceptancerule`, acceptanceRule, this.requestHelper.createOptions()).toPromise();
  }

  async findOne(): Promise<any> {
    return await this._http.get(`${this.url}acceptancerule`, this.requestHelper.createOptions()).toPromise();
  }

  async update(acceptanceRule: AcceptanceRuleModel): Promise<any> {
    return await this._http.put(`${this.url}acceptancerule/${acceptanceRule._id}`, acceptanceRule, this.requestHelper.createOptions()).toPromise();
  }
}
