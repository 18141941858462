import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrderModel } from '../models/order.model';
import { RequestHelper } from '../helpers/requestHelper';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async update(order: OrderModel): Promise<any> {
    return await this._http.put(`${this.url}order`, order, this.requestHelper.createOptions()).toPromise();
  }

  async getById(id): Promise<any> {
    return await this._http.get(`${this.url}orders/${id}`).toPromise();
  }

  async findAllPaginated(query): Promise<any> {
    const params = stringify(query);
    return await this._http.get(`${this.url}orders?${params}`, this.requestHelper.createOptions()).toPromise();
  }

  async updateForSac(order: OrderModel): Promise<any> {
    return await this._http.put(`${this.url}order/updateforsac`, order, this.requestHelper.createOptions()).toPromise();
  }
}
