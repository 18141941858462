import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { stringify } from 'querystring';
import { RequestHelper } from '../helpers/requestHelper';
import { StoreModel } from '../models/store.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async getAllPaginated(params): Promise<any> {
    const query = stringify(params);
    return await this._http.get<any>(`${environment.urlApi}stores?${query}`, this.requestHelper.createOptions()).toPromise();
  }

  async create(store): Promise<any> {
    return await this._http.post<any>(`${environment.urlApi}stores`, store, this.requestHelper.createOptions()).toPromise();
  }

  async getAllPaginatedByFranchiseeId(params): Promise<any> {
    const query = stringify(params);
    const id = JSON.parse(localStorage.getItem('userInfo')).id;
    return await this._http.get<any>(`${environment.urlApi}stores/findbyfranchiseeid/${id}?${query}`, this.requestHelper.createOptions()).toPromise();
  }

  async activate(store): Promise<any> {
    const url = `${environment.urlApi}store/${store._id}`;
    return await this._http.delete<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async getById(id): Promise<any> {
    const url = `${environment.urlApi}store/${id}`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async update(store: StoreModel): Promise<any> {
    const url = `${environment.urlApi}store/${store._id}`;
    return await this._http.put<any>(url, store, this.requestHelper.createOptions()).toPromise();
  }

  async getAll(): Promise<any> {
    const url = `${environment.urlApi}stores/all`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async getAllActive(): Promise<any> {
    const url = `${environment.urlApi}stores/allactive`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async getAllByFranchiseeId(): Promise<any> {
    const id = JSON.parse(localStorage.getItem('userInfo')).id;
    const url = `${environment.urlApi}stores/franchisee/all/${id}`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async getAllByManagerId(): Promise<any> {
    const id = JSON.parse(localStorage.getItem('userInfo')).id;
    const url = `${environment.urlApi}stores/manager/all/${id}`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }
}
