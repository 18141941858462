import { AddressModel } from './address.model';
import { CoordinateModel } from './coordinate.model';

export class UserModel {
    _id: string;
    name: string;
    email: string;
    password: string;
    type: number;
    createdAt: Date;
    updatedAt: Date;
    stores: string[];
    constructor () {
        this.type = 0;
        this.stores = [];
    }
}
