import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpHeaders } from '@angular/common/http';
export class RequestHelper {
    static userType = new JwtHelperService('token').decodeToken(localStorage.getItem('token')) ? new JwtHelperService('token').decodeToken(localStorage.getItem('token')).type : undefined;
    jwt: JwtHelperService = new JwtHelperService('token');
    userDecoded: any;
    authToken: any;
    constructor() {}

    loadToken() {
        const token = localStorage.getItem('token');
        this.authToken = token;
    }

    createOptions(): any {
        this.loadToken();
        if (this.authToken) {
            const expired = this.jwt.isTokenExpired(this.authToken);
            if (!expired) {
                const headers = {
                    headers: new HttpHeaders({
                        'Authorization': this.authToken
                    })
                };
                return headers;
            } else {
                return undefined; // lidar com token expirado
            }
        } else return undefined;

    }

    getUserDecodedInfo() {
        this.userDecoded = this.jwt.decodeToken(localStorage.getItem('token'));
        return this.userDecoded;
    }

    getUserType() {
        return RequestHelper.userType = new JwtHelperService('token').decodeToken(localStorage.getItem('token')) ? new JwtHelperService('token').decodeToken(localStorage.getItem('token')).type : undefined;
    }

}
