import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  transform(value: any): any {
    let type = '';
    value = parseInt(value);
    switch (value) {
      case 0:
        type = 'Admin';
      break;
      case 1:
        type = 'Franqueado';
      break;
      case 2:
        type = 'Gerente';
      break;
      case 3:
      type = 'Comum';
    break;
      default:
      break;
    }
    return type;
  }

}
