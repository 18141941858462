import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() msg: string;
  @Output() closeModal = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    if (!document.querySelector('body').classList.contains('overflow-body-disabled'))
      document.querySelector('body').classList.add('overflow-body-disabled');
  }

  close() {
    this.closeModal.emit();
  }

  ngOnDestroy() {
    if (document.querySelector('body').classList.contains('overflow-body-disabled'))
      document.querySelector('body').classList.remove('overflow-body-disabled');
  }

}
