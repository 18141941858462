import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  errorModal = false;
  successModal = false;
  msg = '';

  constructor() {
    EventEmitterService.get('error-modal').subscribe(e => {
      this.errorModal = true;
      this.msg = e;
    });
    EventEmitterService.get('success-modal').subscribe(e => {
      this.successModal = true;
      this.msg = e;
    });
  }

  ngOnInit() {
  }

  closeErrorModal() {
    this.errorModal = false;
    this.msg = '';
  }

  closeSuccessModal() {
    this.successModal = false;
    this.msg = '';
  }

}
