import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestHelper } from '../helpers/requestHelper';
import { stringify } from 'querystring';

@Injectable()
export class SacManagementService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private httpClient: HttpClient) { }

  async findAllPaginated(filters: any): Promise<any> {
    const query = stringify(filters);
      return await this.httpClient.get<any>(`${this.url}V2/sac/storeorders?${query}`, this.requestHelper.createOptions()).toPromise();
  }
}
