import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { stringify } from 'querystring';
import { RequestHelper } from '../helpers/requestHelper';

@Injectable({
  providedIn: 'root'
})
export class StoreOrderService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async findOrdersByUser(filters): Promise<any> {
    const query = stringify(filters);
    return await this._http.get(`${this.url}V2/storeorders?${query}`, this.requestHelper.createOptions()).toPromise();
  }

  async findOrdersForSac(filters): Promise<any> {
    const query = stringify(filters);
    return await this._http.get(`${this.url}storeorders?${query}`, this.requestHelper.createOptions()).toPromise();
  }

  async update(storeOrder): Promise<any> {
    return await this._http.put(`${this.url}V2/storeorder/${storeOrder._id}`, storeOrder,  this.requestHelper.createOptions()).toPromise();
  }

  async sendOrderToSac(storeOrder): Promise<any> {
    return await this._http.put(`${this.url}storeorder/sendtosac/${storeOrder._id}`, storeOrder,  this.requestHelper.createOptions()).toPromise();
  }

  async updateForSac(storeOrder): Promise<any> {
    return await this._http.post(`${this.url}V2/storeorder/updateforsac`, storeOrder,  this.requestHelper.createOptions()).toPromise();
  }

  async getById(id): Promise<any> {
    return await this._http.get(`${this.url}storeorder/${id}`,  this.requestHelper.createOptions()).toPromise();
  }
}
