import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parsePeriod'
})
export class ParsePeriodPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (args.status) {
      case 'Expirado':
        value = `${value} dias`;
        break;
      case 'Entregue':
        value = `${value} dia(s)`;
        break;
      case 'Recusado':
        value = `${value} hrs`;
        break;
      case 'Em andamento':
        value = `${value} dia(s)`;
        break;
      default:
        break;
    }
    return value;
  }

}

@Pipe({
  name: 'parseSla'
})
export class ParseSlaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (args.status) {
      case 'Expirado':
        value = `${value} dia(s)`;
        break;
      case 'Entregue':
        value = `${value} dia(s)`;
        break;
      case 'Recusado':
        value = `${value} hrs`;
        break;
      case 'Em andamento':
        value = `${value} hrs`;
        break;
      default:
        break;
    }
    return value;
  }

}
