import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterQuantity'
})
export class FilterQuantityPipe implements PipeTransform {

  transform(value: any[], sku: any): any {
    if (sku) {
      const quantity = value.find(v => v.sku === sku);
      return quantity.quantities;
    } else return [];
  }

}
