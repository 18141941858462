import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseNumbers'
})
export class ParseNumbersPipe implements PipeTransform {

  transform(value: number): any {
    if (value) {
      if (value.toString().indexOf('.') > -1) {
        return value.toString().substring(0, value.toString().indexOf('.'));
      }
    }
    return value;
  }

}

@Pipe({
  name: 'validateNumbers'
})
export class ValidateNumbersPipe implements PipeTransform {

  transform(value: number): any {
    if (value) {
      return (value < 10 ? `0${value}` : value);
    }
    return value;
  }

}

