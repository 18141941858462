import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth.guard';
import { AuthLayoutComponent } from './layout/auth/auth-layout.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { TitleComponent } from './layout/title/title.component';
import { MenuComponent } from './layout/menu/menu.component';
import { OrdersService } from './services/orders.service';
import { AdminComponent } from './layout/admin/admin.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { StoreOrderService } from './services/store-order.service';
import { BrowserModule } from '@angular/platform-browser';
import { AcceptanceRuleService } from './services/acceptance-rule.service';
import { SocketService } from './services/socket.service';
import { NotificationComponent } from './layout/notification/notification.component';
import { EventEmitterService } from './services/event-emitter.service';
import { SafetyStockService } from './services/safety-stock.service';
import { NotificationService } from './services/notification.service';
import { ShippingService } from './services/shipping.service';
import { CepService } from './services/cep.service';
import { HollydayService } from './services/hollyday.service';
import { SacManagementService } from './services/sac-management.service';
import { StoreService } from './services/store.service';
import { SalesService } from './services/sales.service';
import { SpreadsheetsService } from './services/spreadsheets.service';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    TitleComponent,
    MenuComponent,
    AdminComponent,
    FooterComponent,
    HeaderComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    SharedModule
  ],
  providers: [
    UserService,
    AuthGuard,
    OrdersService,
    NotificationService,
    ShippingService,
    StoreOrderService,
    AcceptanceRuleService,
    SocketService,
    EventEmitterService,
    SafetyStockService,
    CepService,
    HollydayService,
    SacManagementService,
    StoreService,
    SalesService,
    SpreadsheetsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
