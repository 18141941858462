import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestHelper } from '../helpers/requestHelper';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async findShippingByUser(id): Promise<any> {
    return await this._http.get(`${this.url}storeshipping/${id}`, this.requestHelper.createOptions()).toPromise();
  }

  async update(shipping): Promise<any> {
    return await this._http.put(`${this.url}storeshipping`, shipping,  this.requestHelper.createOptions()).toPromise();
  }

  async create(shipping): Promise<any> {
    return await this._http.post(`${this.url}storeshipping`, shipping,  this.requestHelper.createOptions()).toPromise();
  }

  async delete(shipping): Promise<any> {
    return await this._http.delete(`${this.url}storeshipping/${shipping._id}`,  this.requestHelper.createOptions()).toPromise();
  }
}
