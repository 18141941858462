import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth/auth-layout.component';
import { AuthGuard } from './services/auth.guard';
import { AdminComponent } from './layout/admin/admin.component';

export const AppRoutes: Routes = [{
  path: '',
  component: AuthLayoutComponent,
  children: [
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
    },
    {
      path: 'login',
      loadChildren: './pages/login/login.module#LoginModule'
    },
    {
      path: 'reset',
      loadChildren: './pages/reset-password/reset-password.module#ResetPasswordModule'
    }
  ]
}, {
  path: '',
  component: AdminComponent,
  children: [
    {
      path: '',
      redirectTo: 'pedidos',
      pathMatch: 'full'
    },
    {
      path: 'pedidos',
      canActivate: [AuthGuard],
      loadChildren: './pages/orders/orders.module#OrdersModule'
    },
    {
      path: 'configuracoes',
      canActivate: [AuthGuard],
      loadChildren: './pages/configurations/configurations.module#ConfigurationsModule'
    },
    {
      path: 'promocoes',
      canActivate: [AuthGuard],
      loadChildren: './pages/sales-rules/sales-rules.module#SalesRulesModule'
    },
    {
      path: 'sac',
      canActivate: [AuthGuard],
      loadChildren: './pages/sac/sac.module#SacModule'
    },
    {
      path: 'usuarios',
      canActivate: [AuthGuard],
      loadChildren: './pages/users/users.module#UsersModule'
    },
    {
      path: 'historico',
      canActivate: [AuthGuard],
      loadChildren: './pages/historic/historic.module#HistoricModule'
    },
    {
      path: 'feriados',
      canActivate: [AuthGuard],
      loadChildren: './pages/hollyday/hollyday.module#HollydayModule'
    },
    {
      path: 'lojas',
      canActivate: [AuthGuard],
      loadChildren: './pages/stores/stores.module#StoresModule'
    },
    {
      path: 'vendas',
      canActivate: [AuthGuard],
      loadChildren: './pages/sales/sales.module#SalesModule'
    },
    {
      path: 'stock',
      canActivate: [AuthGuard],
      loadChildren: './pages/stock/stock.module#StockModule'
    },
    {
      path: 'crons',
      canActivate: [AuthGuard],
      loadChildren: './pages/crons/crons.module#CronsModule'
    },
    {
      path: 'prices',
      canActivate: [AuthGuard],
      loadChildren: './pages/prices/prices.module#PricesModule'
    },
    {
      path: 'services',
      canActivate: [AuthGuard],
      loadChildren: './pages/services/services.module#ServicesModule'
    },
    {
      path: 'store/franchisee/services',
      canActivate: [AuthGuard],
      loadChildren: './pages/store/franchisee/services/services.module#ServicesModule'
    },
    {
      path: 'massive-actions',
      canActivate: [AuthGuard],
      loadChildren: './pages/massive-actions/massive-actions.module#MassiveActionsModule'
    },
    {
        path: 'payments',
        canActivate: [AuthGuard],
        loadChildren: './pages/payments/payments.module#PaymentsModule'
      }
  ]
}];
