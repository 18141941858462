import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private store_token: BehaviorSubject<string>;
  constructor() { 
    this.store_token = new BehaviorSubject<string>('tokenteste');
  }

  setStoreToken(new_token): void{
    this.store_token.next(new_token);
  }

  getStoreToken(): Observable<string> {
    return this.store_token.asObservable();
  }
}
