export class AddressModel {
    city: string;
    postalCode: string;
    street: string;
    telephone: string;
    number: number;
    state: string;
    complement: string;
    neighborhood: string;
    latitude: string;
    longitude: string;
    uf: string;
    constructor() {
        this.postalCode = '';
        this.street = '';
        this.telephone = '';
        this.state = '';
        this.complement = '';
        this.neighborhood = '';
        this.uf = '';
    }

    loadModel(response: any) {
        this.city = response.city ? response.city : this.city;
        this.postalCode = response.postalCode ? response.postalCode : this.postalCode;
        this.street = response.street ? response.street : this.street;
        this.telephone = response.telephone ? response.telephone : this.telephone;
        this.number = response.number ? response.number : this.number;
        this.state = response.state ? response.state : this.state;
        this.complement = response.complement ? response.complement : this.complement;
        this.neighborhood = response.neighborhood ? response.neighborhood : this.neighborhood;
        this.latitude = response.latitude ? response.latitude : this.latitude;
        this.longitude = response.longitude ? response.longitude : this.longitude;
        this.uf = response.uf;
    }
}
