import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestHelper } from '../helpers/requestHelper';
import { HollydayModel } from '../models/hollyday.model';
import { stringify } from "querystring";

@Injectable()
export class HollydayService {
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async create (holidays: HollydayModel[]): Promise<any> {
    const url = `${environment.urlApi}holidays`;
    return await this._http.post<any>(url, holidays, this.requestHelper.createOptions()).toPromise();
  }

  async getStates (): Promise<any> {
    const url = `${environment.urlApi}holidays/states`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async getNationalHolidays (): Promise<any> {
    const url = `${environment.urlApi}holidays/national`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async filterAll (filters): Promise<any> {
    const query = stringify(filters);
    const url = `${environment.urlApi}holidays?${query}`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async getStateCities (uf): Promise<any> {
    const url = `${environment.urlApi}holidays/statecities/${uf}`;
    return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
  }

  async inactivateHollyday (id): Promise<any> {
    const url = `${environment.urlApi}hollyday/${id}`;
    return await this._http.delete<any>(url, this.requestHelper.createOptions()).toPromise();
  }

}
