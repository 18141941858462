import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  transform(value: any, countryFlag: string) {
    switch (countryFlag) {
      case 'BR':
        if (value) {
          const valueFormat = value.toFixed(2).split('.');
          valueFormat[0] = `R$ ${valueFormat[0].split(/(?=(?:...)*$)/).join('.')}`;
          return `${valueFormat.join(',')}`;
        }
        break;
      case 'US':
        if (value) {
          const valueFormat = value.toFixed(2).split('.');
          valueFormat[0] = `$ ${valueFormat[0].split(/(?=(?:...)*$)/).join('.')}`;
          return `${valueFormat.join(',')}`;
        }
        break;
      default:
        if (value) {
          const valueFormat = value.toFixed(2).split('.');
          valueFormat[0] = `R$ ${valueFormat[0].split(/(?=(?:...)*$)/).join('.')}`;
          return `${valueFormat.join(',')}`;
        }
        break;
    }
    return value;
  }
}
