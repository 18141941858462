import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationComponent } from '../notification/notification.component';
import { NotificationService } from 'src/app/services/notification.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name = '';
  image = '';
  width = window.innerWidth;
  notificationPanel = false;
  hasNotification = false;
  constructor(private router: Router, private notificationService: NotificationService) { }

  ngOnInit() {
    this.name = JSON.parse(localStorage.getItem('userInfo')).name;
    this.getNotifications();
    this.notificationService.notificationSource.subscribe(r => {
      if (r) {
        this.changeNotif();
        this.hasNotification = true;
      }
    });
  }

  async getNotifications() {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    try {
        const notifications = await this.notificationService.getByUserId(user.id);
        if (notifications.count > 0) {
            this.hasNotification = true;
        } else {
          this.hasNotification = false;
        }
    } catch (error) {
        EventEmitterService.get('error-modal').emit(error.error.friendly_message);
    }
}


  changeNotif() {
    if (this.notificationPanel) {
      NotificationComponent.closeNotif.emit();
    }
    this.getNotifications();
    this.notificationPanel = !this.notificationPanel;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
  }

}
