import { Injectable } from '@angular/core';
import { SafetyStockModel } from '../models/safety-stock.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestHelper } from '../helpers/requestHelper';

@Injectable({
  providedIn: 'root'
})
export class SafetyStockService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();
  constructor(private _http: HttpClient) { }

  async create(safetyStock: SafetyStockModel): Promise<any> {
    return await this._http.post<any>(`${this.url}safety-stock`, safetyStock, this.requestHelper.createOptions()).toPromise();
  }

  async update(safetyStock: SafetyStockModel): Promise<any> {
    return await this._http.put<any>(`${this.url}safety-stock/${safetyStock._id}`, safetyStock, this.requestHelper.createOptions()).toPromise();
  }

  async getByStoreId(storeId): Promise<any> {
    return await this._http.get<any>(`${this.url}safety-stock/${storeId}`, this.requestHelper.createOptions()).toPromise();
  }
}
