import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RequestHelper } from '../helpers/requestHelper';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserModel } from '../models/user.model';
import { stringify } from 'querystring';

@Injectable()
export class UserService {
    jwt: JwtHelperService = new JwtHelperService('token');
    requestHelper: RequestHelper = new RequestHelper();

    constructor(private _http: HttpClient) {
    }

    isLoggedIn(): boolean {
        const token = localStorage.getItem('token');
        let isExpired = false;
        if (token) {
            isExpired = !this.jwt.isTokenExpired(token);
        }
        return isExpired;
    }

    async login(body): Promise<any> {
        const url = `${environment.urlApi}auth`;
        return await this._http.post<any>(url, body).toPromise();
    }

    async forgotPass(body): Promise<any> {
        const url = `${environment.urlApi}auth/forgotpass`;
        return await this._http.post<any>(url, body).toPromise();
    }

    async resetPass(body): Promise<any> {
        const url = `${environment.urlApi}auth/resetpass`;
        return await this._http.post<any>(url, body).toPromise();
    }

    async getUserById(): Promise<any> {
        const url = `${environment.urlApi}user`;
        return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
    }

    async getUserByIdAdmin(id): Promise<any> {
        const url = `${environment.urlApi}user/${id}`;
        return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
    }

    async update(user: UserModel): Promise<any> {
        const url = `${environment.urlApi}user`;
        return await this._http.put<any>(url, user, this.requestHelper.createOptions()).toPromise();
    }

    async activate(user: UserModel): Promise<any> {
        const url = `${environment.urlApi}user/${user._id}`;
        return await this._http.delete<any>(url, this.requestHelper.createOptions()).toPromise();
    }

    async findAllPaginated(query): Promise<any> {
        const queryParams = stringify(query);
        const url = `${environment.urlApi}users?${queryParams}`;
        return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
    }

    async create(userModel: UserModel): Promise<any> {
        const url = `${environment.urlApi}users`;
        return await this._http.post<any>(url, userModel, this.requestHelper.createOptions()).toPromise();
    }

    async getallfranchisees(): Promise<any> {
        const url = `${environment.urlApi}users/getallfranchisees`;
        return await this._http.get<any>(url, this.requestHelper.createOptions()).toPromise();
      }
}
