import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestHelper } from '../helpers/requestHelper';
import { environment } from 'src/environments/environment';
import * as XLSX from "ts-xlsx";

@Injectable({
  providedIn: 'root'
})
export class SpreadsheetsService {

  constructor(private http: HttpClient) {
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result);
      };
  
      reader.onerror = reject;
  
      reader.readAsArrayBuffer(file);
    })
  }

  arrayBufferToString(arrayBuffer, decoderType = 'utf-8') {
    let decoder = new TextDecoder(decoderType);
    return decoder.decode(arrayBuffer);
  }

  async xlsxFileToJson(file){
    let arrayBuffer: any;
    arrayBuffer = await this.readFileAsync(file);
    let data = new Uint8Array(arrayBuffer);
    let arr = new Array();
    for (let i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
    let bstr = arr.join("");
    let workbook = XLSX.read(bstr, { type: "binary" });
    let first_sheet_name = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[first_sheet_name];
    return XLSX.utils.sheet_to_json(worksheet, { raw: true });
  }

  validateJsonFile(json_file, headers, columns_type){
    let validated_json = [];
    let row_counter = 0;
    for(let row of json_file){
        let row_obj: any;
        row_obj = row;
        let key_counter = 0;
        let validated_obj = [];

        for(let key in row_obj){
            if(this.normalizeText(key) != this.normalizeText(headers[key_counter])){
                let headers_str = '';
                for(let i = 0; i < headers.length; i++){
                    let header = headers[i];
                    if(i == headers.length - 1)
                        headers_str+= ` e ${header}`;
                    else
                        if(i > 0)
                          headers_str+= `${header},`
                        else
                          headers_str+= `${header}`
                  }
                return {
                    success: false,
                    message: `A coluna ${key} não é válida. A planilha deve conter apenas as colunas na ordem ${headers_str}.`
                }
            }
            let validated_value = this.validateType(row[key], columns_type[key_counter]);
            if(validated_value == null)
                return{
                    success: false,
                    message: `Verifique a linha ${row_counter + 2}. O valor ${row[key]} não está no formato correto.`
                }    

            validated_obj.push(validated_value);
            key_counter++;
        }

        validated_json.push(validated_obj);
        row_counter++;
    }
    return{
        success: true,
        message: `Ok`,
        data: validated_json
    }
  }

  normalizeText(text){
    text = text.toLowerCase();
    return text.replace(/_/g, '')
                .replace(/-/g, '')
                .replace(/ /g, '')
                .replace(/|/g, '')
                .replace(/,/g, '')
                .replace(/;/g, '')
  }

  validateOptionalFields(file, column_names, optionals, default_fields){
    let final_data = []
    for(let row of file){
      let new_obj = {};
      let counter = 0;
      for(let column_name of column_names){
        let new_value = row[column_name]
        if(!new_value && optionals[counter] == true)
          return {
            success: false,
            message: `O campo ${column_name} é obrigatório.`,
            data: []
          };
        if(!new_value)
          new_obj[column_name] = default_fields[counter];
        else
        new_obj[column_name]  = new_value;
        counter++;
      }
      final_data.push(new_obj);
    }
    return {
      success: true,
      message: 'Ok',
      data: final_data
    };
  }

  validateType(value, type){
    if(type == 'SKU'){
        if(!(/^\d+$/.test(value))) //Precisa ser apenas números
            return null;
        return value.toString();
    }
    if(type == 'STOCK'){        
        if(!(/^\d+$/.test(value))) //Precisa ser apenas números
            return null; 
        return parseInt(value);
    }
    if(type == 'PRICE'){
      try{
        return parseFloat(value.toString().replace(',', '.'));
      }
      catch(error){
        return null;
      } 
    }
    if(type == 'BOOLEAN'){
      if((value != 'SIM') && (value != 'NAO')) //Precisa ser SIM OU NAO
          return null; 
      return value;
    }
    if(type == 'DATE'){
      return value;
    }
    return null;
  }

}
