import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestHelper } from '../helpers/requestHelper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  url = environment.urlApi;
  requestHelper: RequestHelper = new RequestHelper();

  public notificationSource = new BehaviorSubject(null);
  notification = this.notificationSource.asObservable();

  constructor(private _http: HttpClient) { }

  async read(notification): Promise<any> {
    return await this._http.put<any>(`${this.url}notification/${notification}`, null , this.requestHelper.createOptions()).toPromise();
  }

  async getByUserId(userId): Promise<any> {
    return await this._http.get<any>(`${this.url}notification/${userId}?page=1&per_page=10`, this.requestHelper.createOptions()).toPromise();
  }

}
