import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bottom-track',
  templateUrl: './bottom-track.component.html',
  styleUrls: ['./bottom-track.component.scss']
})
export class BottomTrackComponent implements OnInit {
  @Input() color: string;
  constructor() { }

  ngOnInit() {
  }

}
