import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddressModel } from '../models/address.model';

@NgModule()

export class CepService {

    constructor(private _http: HttpClient) { }

    async search(cep: string): Promise<any> {
        return await this._http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).toPromise();
    }

    convertToModel(c) {
        const cep: AddressModel = new AddressModel();
        cep.postalCode = c.cep;
        cep.street = c.logradouro;
        cep.complement = c.complemento;
        cep.neighborhood = c.bairro;
        cep.city = c.localidade;
        cep.state = c.uf.toUpperCase();
        cep.uf = c.uf.toUpperCase();
        return cep;
    }
}
