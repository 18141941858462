import { Component, OnInit, EventEmitter } from '@angular/core';
import { SocketService } from 'src/app/services/socket.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EventEmitterService } from '../../services/event-emitter.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    static closeNotif: EventEmitter<any> = new EventEmitter<any>();
    notifs: any[] = [];
    loading = true;
    firstLoad = 0;
    constructor(private notificationService: NotificationService) {
        EventEmitterService.get('notification').subscribe(data => {
            this.notifs.push(data);
        });
    }

    ngOnInit() {

        this.notificationService.notificationSource.subscribe(notification => {
            if (notification) {
                this.notifs.push(notification);
            }
        });
        this.getNotifications();

        setInterval(() => {
            if (this.notifs) {
                for (const notif of this.notifs) {
                    if (notif.time.hours > 0) {
                        if (notif.time.minutes > 0) {
                            notif.time.current++;
                            notif.time.minutes--;
                        } else {
                            notif.time.hours--;
                            notif.time.minutes = 59;
                            notif.time.current++;
                        }
                    } else {
                        notif.time.minutes--;
                        notif.time.current++;
                    }
                }
            }
        }, 60000);
    }

    async getNotifications() {
        const user = JSON.parse(localStorage.getItem('userInfo'));
        try {
            const notifications = await this.notificationService.getByUserId(user.id);
            if (notifications.count > 0) {
                for (const result of notifications.result) {
                    this.notifs.push(result);
                }
                this.loading = false;
            } else {
                this.loading = false;
            }
        } catch (error) {
            EventEmitterService.get('error-modal').emit(error.error.friendly_message);
        }
    }

    async removeNotification(notif) {

        for (let i = 0; i < this.notifs.length; i++) {
            if (this.notifs[i]._id === notif._id) {
                this.notifs.splice(i, 1);
            }
        }
        await this.notificationService.read(notif._id);
    }

    async removeAllNotifications() {
        await this.notifs.map(async noticication => {
            await this.notificationService.read(noticication._id);
        });

        this.notifs = [];

    }
}
