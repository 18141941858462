import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Output() changePage = new EventEmitter<number>();
  @Input() totalItems: number;
  @Input() page: number;
  @Input() limit: number;
  array: any = [];
  constructor() { }
  ngOnInit() {
    this.array = this.range(0, this.totalItems);
  }

  activePage(item: number) {
    const page = item - 1;
    this.changePage.emit(page);
  }

  range(_p: number, _t?: number, _s?: number): Array<number> {
    /**
     * @param <_p> Return a list integers of zero until <_p> value.
     * @param <_t> Return a list integers of <_t> until <_p> value.
     * @param <_s> Return a list integers of <_t> until <_p> with steps <_s> value.
     * @return Return a array list
     */

    const start: number = (_t) ? _p : 0;
    const stop: number = (_t) ? _t : _p;
    const step: number = (_s) ? _s : 1;

    const t: Array<number> = [];
    for (let i = start; i < stop; i = i + step) {
      t.push(i);
    }

    return t;
  }

}
