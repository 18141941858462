import { Component, OnInit, HostListener } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserModel } from 'src/app/models/user.model';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  jwtHelper: JwtHelperService = new JwtHelperService();
  user: UserModel = new UserModel();
  width = window.innerWidth;
  selectedItem = '';
  constructor() {
    this.user = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    EventEmitterService.get('selectedItem').subscribe(event => this.selectedItem = event);
  }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
  }

  select(item) {
    this.selectedItem = item;
  }

  @HostListener('window:scroll', [])
  scroll() {
    if (this.width > 767) {
      const menu = document.getElementById('menu-petland');
      if (window.scrollY > 60) {
        menu.style.position = 'fixed';
        menu.style.top = '0';
        menu.style.width = '16.666667%';
      } else {
        menu.style.position = 'relative';
        menu.style.top = 'none';
        menu.style.width = '100%';
      }
    }
  }

}
