import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  errorModal = false;
  msg = '';
  successModal = false;
  constructor() {
    EventEmitterService.get('error-modal').subscribe(e => {
      this.errorModal = true;
      this.msg = e;
    });
    EventEmitterService.get('success-modal').subscribe(e => {
      this.successModal = true;
      this.msg = e;
    });
   }

  ngOnInit() {
  }

  closeErrorModal() {
    this.errorModal = false;
    this.msg = '';
  }

  closeSuccessModal() {
    this.successModal = false;
    this.msg = '';
  }

}
